import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Wood from "./pages/Wood";
import wassup from './asset/images/layout/wassup.png'
import { Helmet } from "react-helmet";
import WoodDetail from "./pages/WoodDetail";
import Tomper from "./pages/Tomper";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  <Helmet>
    <title>Platinum</title>
    <meta
      name="description"
      content="Platinum International Trading and Contracting"
    />
  </Helmet>;
  const [time, settime] = useState(true);

  const [direction, setdirection] = useState("ltr");
  const [ShowNav, setShowNav] = useState(false);
  const [lang, setLang] = useState("en-US");
  useEffect(() => {
    const lang = localStorage?.getItem("i18nextLng");
    setLang(lang === "en-US" ? "en-US" : "ar");
    setdirection(lang === "en-US" ? "ltr" : "rtl");
  }, [lang]);
  

   useEffect(() => {
     setTimeout(() => {
       settime(false);
     }, 2000);
   }, []);


  
  return time ? (
    <div class="loader"></div>
  ) : (
    <div dir={direction} className="relative">
      <div
        className="fixed top-[70vh] z-[10000] right-[0px] bg-[white]  rounded-l-[200px] border-l-2 border-b-2 border-t-2 border-[#05bb05] "
        style={{ direction: "ltr" }}
      >
        <a href="https://wa.me/97440197722">
          <img
            src={wassup}
            alt=""
            className="dd sm:w-[70%] md:w-[100%] lg:w-[100%]"
            style={{ direction: "ltr" }}
          />
        </a>
      </div>
      <Router>
        <Header
          setLang={setLang}
          lang={lang}
          direction={direction}
          ShowNav={ShowNav}
          setShowNav={setShowNav}
        />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home direction={direction} />} />
            <Route
              path="/contact"
              element={<Contact direction={direction} />}
            />
            <Route path="/about" element={<About direction={direction} />} />
            <Route
              path="/stationary"
              element={<Wood direction={direction} />}
            />
            <Route path="/wood" element={<Tomper direction={direction} />} />
            <Route
              path="/stationary/:details"
              element={<WoodDetail direction={direction} />}
            />
          </Routes>
        </ScrollToTop>
        <Footer direction={direction} />
      </Router>
    </div>
  );
}

export default App;
