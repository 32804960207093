import React from 'react'
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import front from "../asset/images/wood/front.jpg"
function OurTimper({ direction }) {
  const { t}=useTranslation()
  return (
    <div>
      <div className="w-[90%] m-auto flex  mt-[5%] lg:flex-row items-center md:flex-row sm:flex-col">

        <img
          src={front}
          alt="Wood Building materials Qatar"
          className="lg:h-[407px] md:h-[290px] sm:h-[200px] lg:w-[30%] md:w-[35%] sm:w-[70%] sm:m-auto lg:m-0 md:m-0"
        />
        <div className={direction==="ltr"? "lg:ml-[7%] lg:w-[65%]  md:ml-[7%] md:w-[60%] sm:w-[90%] sm:text-center lg:text-left md:text-left lg:m-0 md:m-0 sm:m-auto ":"lg:mr-[7%] lg:w-[65%]  md:mr-[7%]  md:w-[60%] sm:w-[90%] sm:text-center lg:text-right md:text-right lg:m-0 md:m-0 sm:m-auto "}>
          <h1 className="text-[#fff] luc  lg:text-5xl md:text-3xl sm:text-2xl lg:mt-[10%] md:mt-[5%] underline underline-offset-4   sm:mt-[5%]   ">
            {t("Our timber")}
          </h1>
          <Fade bottom>

          <p className="block  mt-[5%] lg:text-3xl md: md:text-xl ">
            {t(
              "We source dry wood according to demand in terms of variety and humidity level, delivering the best quality wood in Qatar that excels in sturdiness and quality with huge a variety of sizes and shapes"
            )}
          </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default OurTimper