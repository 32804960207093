import React from 'react'
import { useTranslation } from 'react-i18next';
import OurTimper from '../components/OurTimper';
import Products from '../components/Products';
import TimperText from '../components/TimperText';
import bottom from "../asset/images/wood/botton.png";
import bg from "../asset/images/contact/bgcontact.png";
import { Helmet } from 'react-helmet';
function Tomper({direction}) {
  const { t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>Platinum | Wood Suppliers in Qatar</title>
        <meta
          name="description"
          content="whether you are looking for building materials, wood products, douglas fir timber, or Douglas beech timber check our website to find all your needs."
        />
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${bg} )`,
          backgroundSize: "cover",
          zIndex: "5",
        }}
        className="bg-[#d1d1d1]"
      >
        {/* slider */}
        <div
          className=" relative w-[100vw] lg:h-[82vh]  md:h-[350px]  sm:h-[38vh]  shad xs:pt-[7%] sm:pt-[7%] md:pt-[1%]  lg:pt-[1%] text-white "
          style={{
            backgroundImage: "url(./images/home/slider3.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="text-center mt-[12%] lg:text-5xl md:text-3xl sm:text-xl sm:pt-[5%] lg:pt-0 md:pt-0 luc">
            {t("We provide the best quality Beech and Fir wood")}
          </h1>
        </div>
        {/* OurTimper */}
        <OurTimper direction={direction} />

        {/* Products */}
        <Products direction={direction}  />
        {/* TimperText */}
        <TimperText direction={direction} />
        {/* img */}
        <img
          src={bottom}
          alt=""
          className="m-auto lg:w-[40%] md:w-[50%] sm:w-[60%] pb-[2%] mt-[5%]"
        />
      </div>
    </>
  );
}

export default Tomper