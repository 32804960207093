import React from "react";
import Contactbut from "../components/Contactbut";
import ContactHead from "../components/ContactHead";
import bg from "../asset/images/contact/bgcontact.png";
import face from "../asset/images/contact/Icon ionic-logo-facebook.png";
import inst from "../asset/images/contact/instagram-square.png";
import link from "../asset/images/contact/Icon awesome-linkedin.png";
import { Helmet } from "react-helmet";
function Contact() {
  return (
    <>
      <Helmet>
        <title>Platinum | Buy Papers and wood online</title>
        <meta
          name="description"
          content="Where to buy paper and wood in Doha, Qatar? Contact us on:
+94 3007 0190"
        />
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${bg} )`,
          backgroundSize: "cover",
          zIndex: "5",
        }}
        className="md:min-h-[85vh] md:h-[100%] lg:h-auto sm:h-auto pb-[10%]"
      >
        <ContactHead />
        {/* for cnteralize */}
        <div className="w-[100vw] flex justify-center items-center ">
          <div className="flex items-center lg:w-[15%] md:w-[20%] sm:w-[50%]   py-[5%] justify-between">
            <a
              href="https://www.facebook.com/profile.php?id=100081344557861"
              className="lg:w-[25%] w-[22%] "
            >
              <img src={face} alt="facebook icon" />
            </a>
            <a
              href="https://www.instagram.com/pitc.qa/?igshid=YmMyMTA2M2Y%3D"
              className="lg:w-[25%] w-[22%] "
            >
              <img src={inst} alt="instagram icon" className="" />
            </a>
            <a
              href="https://www.linkedin.com/company/platinum-internation/"
              className="lg:w-[25%] w-[22%] "
            >
              <img src={link} alt="linkedIn icon" />
            </a>
          </div>
        </div>
        <Contactbut />
      </div>
    </>
  );
}

export default Contact;
