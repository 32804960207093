import React from 'react'
import addr from "../asset/images/contact/Icon material-my-location.png";
import { useTranslation } from "react-i18next";
import cont from "../asset/images/contact/Icon ionic-md-contacts.png";
import phon from "../asset/images/contact/Icon material-phone-iphone.png";
import { Fade } from 'react-reveal';
function Contactbut() {
  const {t}=useTranslation()
 
  return (
    <div className=" lg:w-[80%] md:w-[85%] w-[90%] m-auto  flex justify-between font-serif lg:h-[180px] md:h-[140px] h-[400px]  lg:flex-row md:flex-row sm:flex-col xs:flex-col  ">
      <Fade bottom>
        {/* item1 */}
        <a
          href="https://goo.gl/maps/AkfFooD7BRR1XkBH6"
          className={`bg-[#fff] text-[#000] lg:w-[32%] md:w-[32%] w-[60%] pb-[2%] xs:mt-[2%] sm:mt-[2%] lg:mt-0 md:mt-0 lg:m-0 md:m-0 m-auto   text-center border-[#24A7B0] border-[2px]`}
        >
          <img
            src={addr}
            alt=""
            className=" m-auto mt-[10%] w-[25px] h-[30px] "
          />
          <p className="mt-[10px] uppercase lg:text-2xl md:text-xl sm:text-lg">
            {" "}
            {t("Address")}
          </p>
          <p className="lg:text-lg  mt-[2%] md:text-sm text-xs ">
            9th Floor Marina Twin Tower A,Lusail City, Qatar
          </p>
        </a>
        {/* item2 */}
        <a
          href="mailto:Info@p-ITC.com"
          className={`bg-[#24A7B0] text-[#fff] lg:w-[32%] md:w-[32%] w-[60%] pb-[2%] xs:mt-[2%] sm:mt-[2%] lg:mt-0 md:mt-0 lg:m-0 md:m-0 m-auto   text-center border-[#24A7B0] border-[2px]`}
        >
          <img
            src={cont}
            alt=""
            className=" m-auto mt-[10%] w-[25px] h-[30px] "
          />
          <p className="mt-[10px] uppercase lg:text-2xl md:text-xl sm:text-lg">
            {" "}
            {t("Contact")}
          </p>
          <p className="lg:text-lg  mt-[2%] md:text-sm text-xs ">
            Info@p-ITC.com
          </p>
        </a>
        {/* item3 */}
        <a
          href="tel:+974 40197722"
          className={`bg-[#fff] text-[#000] lg:w-[32%] md:w-[32%] w-[60%] pb-[2%] xs:mt-[2%] sm:mt-[2%] lg:mt-0 md:mt-0 lg:m-0 md:m-0 m-auto   text-center border-[#24A7B0] border-[2px]`}
        >
          <img
            src={phon}
            alt=""
            className=" m-auto mt-[10%] w-[20px] h-[30px] "
          />
          <p className="mt-[10px] uppercase lg:text-2xl md:text-xl sm:text-lg">
            {" "}
            {t("phone")}
          </p>
          <p className="lg:text-lg  mt-[2%] md:text-sm text-xs  ">
            +974 40197722
          </p>
        </a>
      </Fade>
    </div>
  );
}

export default Contactbut