import React from 'react'
import { useTranslation } from 'react-i18next';
 import imge3 from "../asset/images/stationary/Group 81.png";
 import imge4 from "../asset/images/stationary/Group 80.png";
 import imge5 from "../asset/images/stationary/Group 79.png";
 import imge6 from "../asset/images/stationary/Group 78.png";
 import imge7 from "../asset/images/stationary/Group 85.png";
 import imge8 from "../asset/images/stationary/Group 84.png";
 import imge9 from "../asset/images/stationary/Group 83.png";
 import imge10 from "../asset/images/stationary/Group 82.png";
import { Fade } from 'react-reveal';
 
function DetailFeatures() {
  const images1 = [
    {
      id: 1,
      title: "User Friendly Box with Handles for Quick Ream Access",
      image: imge3,
    },
    {
      id: 2,
      title: "High Whiteness",
      image: imge4,
    },
    {
      id: 3,
      title: "Superb Smoothness for Exceptional Print",
      image: imge5,
    },
    {
      id: 4,
      title: "Precision Cut Edges",
      image: imge6,
    },
  ];
  const images2 = [
    {
      id: 1,
      title: "High Thickness for Duplex Printing",
      image: imge7,
    },
    {
      id: 2,
      title: "High Opacity",
      image: imge8,
    },
    {
      id: 3,
      title: "ProDigi™ HD Print Technology",
      image: imge9,
    },
    {
      id: 4,
      title: "PEFC Certified",
      image: imge10,
    },
  ];
  const {t}=useTranslation()
  return (
    <div className="mt-[10%]">
      <h1 className=" text-center lg:text-4xl uppercase luc md:text-3xl sm:text-2xl">
        {t("Features & Benefits")}
      </h1>
      <div className=" w-[80%] flex m-auto justify-between flex-wrap mt-[5%] luc">
        {images1.map((item) => (
          <Fade bottom cascade>

          <div key={item.id} className=" lg:w-[20%] md:w-[20%] sm:w-[40%] ">
            <img
              src={item.image}
              alt=""
              className="w-[70%] m-auto sm:mt-[6%] lg:mt-0 md:mt-0"
            />
            <p className="text-center lg:text-xl  sm:text-xs md:text-xs ">
              {t(`${item.title}`)}
            </p>
          </div>
          </Fade>
        ))}
      </div>
      <div className=" w-[80%] flex m-auto justify-between flex-wrap mt-[5%] luc">
        {images2.map((item) => (
          <Fade bottom cascade>

          <div key={item.id} className=" lg:w-[20%] md:w-[20%] sm:w-[40%] ">
            <img
              src={item.image}
              alt=""
              className="w-[70%] m-auto sm:mt-[6%] lg:mt-0 md:mt-0"
            />
            <p className="text-center lg:text-xl  sm:text-xs md:text-xs ">
              {t(`${item.title}`)}
            </p>
          </div>
          </Fade>
        ))}
      </div>
    </div>
  );
}

export default DetailFeatures