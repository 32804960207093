import React from 'react'
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import point from "../asset/images/wood/Ellipse 4.png"

function TimperText({ direction}) {
  const {t}=useTranslation()
  return (
    <div
      className={
        direction === "ltr"
          ? "text-2xl ref  pl-[5%] lg:mt-[3%] md:mt-[3%]  sm:w-[90%] sm:m-auto lg:w-auto md:w-auto lg:m-0 md:m-0 sm:mt-[8%] "
          : "text-2xl ref pr-[5%] lg:mt-[3%] md:mt-[3%]  sm:w-[90%] sm:m-auto lg:w-auto md:w-auto lg:m-0 md:m-0 sm:mt-[8%] "
      }
    >
      <h1 className=" luc lg:text-2xl md:text-xl sm:text-lg text-center sm:m-auto lg:m-0 md:m-0  lg:w-[70%] md:w-[85%] sm:w-[95%] ">
        <span className="lg:text-5xl md:text-3xl sm:text-2xl font-serif mx-[3px] ">
          {t("Woodscapez")}
        </span>
        {t(
          "is renowned for pairing the highest quality wood with advanced sawmills to provide our customers with nothing less than the best that wood suppliers could offer."
        )}
      </h1>
      <Fade bottom>
        <div className="flex  items-center lg:mt-[2%] md:mt-[2%] sm:mt-[7%] lg:text-2xl md:text-xl sm:text-lg  ">
          <img
            src={point}
            alt=""
            className="mx-[2%] lg:w-[1%] md:w-[2%] sm:w-[5%]"
          />
          <p>{t("Offering the highest quality building materials.")}</p>
        </div>
      </Fade>
      <Fade bottom>
        <div className="flex items-center mt-[2%] lg:text-2xl md:text-xl sm:text-lg  ">
          <img
            src={point}
            alt=""
            className="mx-[2%]  lg:w-[1%] md:w-[2%] sm:w-[5%]"
          />
          <p className="lg:w-[53%] md:w-[80%] sm:w-[90%]">
            {t(
              "Minimizing the difficulties and the complications for the client and answering the question of where to buy wood in Qatar."
            )}
          </p>
        </div>
      </Fade>
      <Fade bottom>
        <div className="flex items-center mt-[2%] lg:text-2xl md:text-xl sm:text-lg  ">
          <img
            src={point}
            alt="point"
            className="mx-[2%] lg:w-[1%] md:w-[2%] sm:w-[5%]"
          />
          <p className="md:w-[80%]">
            {t(
              "Advanced machinery to provide you with the best timber Qatar can offer"
            )}
          </p>
        </div>
      </Fade>
    </div>
  );
}

export default TimperText