import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import bg from "../asset/images/about/brandi-redd-aJTiW00qqtI-unsplash.webp"
import img1 from "../asset/images/about/deforestation-environmental-awareness-banner-with-chopped-wood-slice-(1).png"
import img2 from "../asset/images/about/23.png"

function About({ direction }) {
  const [t] = useTranslation();
  const x = useParams();
  const [showmore, setshowmore] = useState(false)
useEffect(() => {
 setshowmore(false)
}, [])


  return (
    <>
      <Helmet>
        <title>Platinum | Paper and wood suppliers in Doha, Qatar</title>
        <meta
          name="description"
          content="Platinum aims to provide high-quality digital paper, and
wood products in Qatar Market."
        />
      </Helmet>
      <div
        className=" bg-[#d1d1d1] relative  font-serif lg:min-h-[100%] md:min-h-[80vh]  sm:min-h-[83vh] pb-[5%]"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
        }}
      >
        {/* header */}
        <div className="  w-[100vw]  lg:pt-[14%] md:pt-[20%] lg:pb-[17%] md:h-[100%]  lg:h-[100%] lg:text-2xl  sm:pt-[25%] md:text-2xl sm:text-base md:w-[95%] sm:w-[90%] m-auto   ref text-white ">
          <h1 className="lg:text-5xl md:text-3xl sm:text-2xl luc text-center">
            {t("About Us")}
          </h1>
          {/* first p-graph */}
          <div className="lg:w-[80%] md:w-[82%] sm:w-[94%]  m-auto text-black lg:text-3xl md:text-2xl sm:text-base  mt-[5%] ref">
            <div>
              <span className="lg:text-5xl md:text-3xl sm:text-xl text-white mx-[4px]">
                {t("Platinum")}
              </span>
              {t(
                "is a trading company in Qatar, providing the market with premium products such as high-quality office stationery, andwood products, with a mission to extend its portfolio of products to serve the Qatari market as best it can."
              )}
            </div>
            <div className="flex lg:flex-row md:flex-row sm:flex-col justify-between items-center lg:mt-[5%] md:mt-[5%] sm:mt-[8%] lg:m-0 md:m-0 sm:m-auto">
              <img
                src={img1}
                alt="Fir wood plank"
                className="lg:w-[42%] md:w-[42%] sm:w-[75%]"
              />
              <img
                src={img2}
                alt="White paper for office and home"
                className="lg:w-[42%] md:w-[42%] sm:w-[75%]  sm:mt-[8%] lg:mt-0 md:mt-0"
              />
            </div>
            <div className="mt-[5%]">
              {t(
                "Platinum is seeking to uplift the quality of the available productsin the market by choosing the best sources from allaround the world to provide the highest quality and efficiency forthe growing Qatari market."
              )}
            </div>
          </div>
        </div>
        {/* bottom */}
      </div>
    </>
  );
}

export default About;
