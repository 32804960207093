import React from 'react'
import { useTranslation } from 'react-i18next';
import Subwood from './Subwood';

function Wood2({ direction}) {
  const {t}=useTranslation()
  return (
    <div className="w-[90%] justify-between m-auto  flex lg:flex-row md:flex-row sm:flex-col-reverse  lg:h-[507px] md:h-[350px]">
      <Subwood direction={direction} />
      <img src="./images/wood/paper factory.png" alt="Paper factory Qatar" />
    </div>
  );
}

export default Wood2