import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from '../asset/images/layout/Logo.png'
import tran from '../asset/images/layout/translate-icon.png'
import arro from '../asset/images/layout/baseline_menu_black_24dp.png'
function Header({ setLang, direction, lang, ShowNav, setShowNav }) {
  const { t, i18n } = useTranslation();
  const [land, setland] = useState("");
  const [showsliderlanguafe, setshowsliderlanguafe] = useState(false);
  const [shownav, setshownav]
  = useState(false);
  const [showcategorymodel, setshowcategorymodel] = useState(false);
 
 
  const location = useLocation();
  const pathname = location.pathname;

  const [changingbylang, setchangingbylang] = useState("");
  useEffect(() => {
    if (direction === "rtl") {
      setLang("العربية");
      setshowsliderlanguafe(true);
    } else {
      setLang("English");
      setshowsliderlanguafe(false);
    }
    i18n.changeLanguage(land);
    setLang(land);
    i18n.changeLanguage(land);
  }, [direction, land, i18n, setLang]);


  useEffect(() => {
    if (direction === "rtl") {
      setchangingbylang("mr-[3%]");
    } else {
      setchangingbylang("ml-[3%]");
    }
    
    
  }, [direction]);
  
  useEffect(() => {
    console.log(pathname);
    setshowsliderlanguafe(false)
    setshownav(false);
    
    
  }, [pathname]);
  

 
  return (
    <div
      className={
        shownav
          ? " fixed z-[100] top-[-2px]  mt-[2%]     shad  "
          : " absolute z-[100] top-0   mt-[2%]    shad  "
      }
    >
      {/* first nav */}
      <div
        className="flex w-[100vw] justify-between items-center px-[2%] lg:mt-[1%] lg:my-[1%] md:my-[1%] lg:h-[50px]
      md:h-[50px]
       relative z-[100] 
       sm:my-[3%]
       xs:my-[3%]
       

    "
      >
        <Link
          to="/"
          className={
            shownav
              ? `  lg:w-[3.5%] md:w-[3.25%] xs:w-[8%] xs:hidden sm:hidden sm:w-[6%] ${changingbylang}  `
              : `  lg:w-[3.5%] md:w-[3.25%] xs:w-[8%] sm:w-[6%] ${changingbylang}  `
          }
        >
          <img src={logo} alt="" className="z-[10000]" />
        </Link>

        <div className="w-[90%] md:w-[75%] pl-[0%] sm:w-[85%] xs:w-[87%] sm:text-xs xs:text-xs lg:text-xl md:text-lg text-white sm:hidden xs:hidden lg:block md:block">
          <ul
            className={` flex lg:w-[50%] md:w-[75%] w-[100%] lg:mx-[0] md:mx-[0]  justify-between float-right lg:mr-[20%]  md:mr-[20%] font-serif `}
          >
            <li className="sm:ml-[10%] xs:ml-[10%] lg:ml-[0] md:ml-[0]">
              <NavLink activeClassName="text-black" to="/">
                {" "}
                {t("Home")}
              </NavLink>
            </li>
            <li
              className="sm:ml-[10%] xs:ml-[10%] lg:ml-[0] md:ml-[0]  flex flex-col border-white border-[3px] relative px-[2%] cursor-pointer"
              onClick={() => setshowcategorymodel(!showcategorymodel)}
            >
              {" "}
              {t("Category")}
              {showcategorymodel && (
                <ul className="absolute top-[33px] bg-white w-[107%] left-[-4px] sm:text-xs xs:text-xs lg:text-md md:text-sm  ">
                  <li className="sm:ml-[10%] xs:ml-[10%] lg:ml-[0] md:ml-[0]">
                    {" "}
                    <Link
                      to="/stationary"
                      className="sm:text-xs px-[2%] lg:text-sm md:text-sm md:mt-[8%] text-black"
                      activeClassName="text-black"
                    >
                      {t("STATIONARY")}
                    </Link>{" "}
                  </li>
                  <li className="sm:ml-[10%] xs:ml-[10%] text-black lg:ml-[0] md:ml-[0]">
                    {" "}
                    <Link
                      to="/wood"
                      className="sm:text-xs px-[2%] text-black lg:text-sm md:text-sm md:mt-[8%] text-black"
                    >
                      {" "}
                      {t("WOOD")}
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink activeClassName="text-black" to="/about">
                {" "}
                {t("About us")}
              </NavLink>{" "}
            </li>
            <li>
              <NavLink activeClassName="text-black" to="/Contact">
                {t("Contact us")}
              </NavLink>
            </li>
            <li className="  md:pr-[0%] lg:pr-[0%]  sm:pr-[5%] xs:pr-[5%] relative cursor-[pointer]  ">
              <div
                onClick={() => {
                  if (showsliderlanguafe === false) {
                    setland("ar");
                  } else {
                    setland("en-US");
                  }
                  setshowsliderlanguafe(!showsliderlanguafe);
                }}
              >
                <img
                  src={tran}
                  alt=""
                  className=" mx-auto lg:mt-[15%] mt-[0]"
                />
              </div>
            </li>
          </ul>
        </div>

        <div
          className=" lg:hidden md:hidden sm:block xs:block   "
          onClick={() => {
            setshownav(true);
          }}
        >
          {!shownav && (
            <div className="border-black border-2  rounded-lg  w-[60%]">
              <img src={arro} className="" alt="" />
            </div>
          )}
        </div>

        {shownav && (
          <>
            <div
              className={
                direction === "ltr"
                  ? "fixed w-[50vw] h-[103vh] bg-[#575757cf] top-[-15px] bottom-[0%] right-0  text-xl "
                  : "fixed w-[50vw] h-[103vh] bg-[#575757cf] top-[-15px] bottom-[0%] left-0  text-xl "
              }
            >
              <p
                onClick={() => setshownav(false)}
                className="text-4xl mx-[10%] my-[4%] text-[red] text-right "
              >
                x
              </p>
              <ul className=" flex w-[100%] flex-col lg:mx-[0] md:mx-[0]  justify-between  text-white font-serif items-center">
                <li className=" mt-[10%] ">
                  <NavLink to="/"  activeClassName="text-black"> {t("Home")}</NavLink>
                </li>
                <li
                  className=" flex flex-col border-white cursor-pointer border-[3px] relative px-[2%] mt-[10%]"
                  onClick={() => setshowcategorymodel(!showcategorymodel)}
                >
                  {" "}
                  {t("Category")}
                  {showcategorymodel && (
                    <ul className="absolute top-[33px] bg-white w-[107%] left-[-4px] sm:text-xs xs:text-xs lg:text-md md:text-lg z-[400]  ">
                      <li className="sm:pl-[10%] pt-[5%] text-black xs:pl-[10%] lg:ml-[0] md:ml-[0]">
                        <NavLink
                          to="/stationary"
                          className="sm:text-xs  text-black px-[2%] lg:text-sm md:text-lg  "
                          activeClassName="text-black"
                        >
                          {" "}
                          {t("STATIONARY")}
                        </NavLink>{" "}
                      </li>
                      <li className="sm:pl-[10%] pt-[8%] text-black xs:pl-[10%] lg:ml-[0] md:ml-[0]">
                        {" "}
                        <Link
                          to="/wood"
                          className="sm:text-xs px-[2%] lg:text-sm md:text-lg text-black"
                          activeClassName="text-black"
                        >
                          {" "}
                          {t("WOOD")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className=" mt-[10%] ">
                  <NavLink to="/about" activeClassName="text-black"> {t("About us")}</NavLink>{" "}
                </li>
                <li className=" mt-[10%] ">
                  <NavLink to="/Contact" activeClassName="text-black">{t("Contact us")}</NavLink>
                </li>
                <li className="  mt-[10%] cursor-[pointer]  ">
                  <div
                    onClick={() => {
                      if (showsliderlanguafe === false) {
                        setland("ar");
                      } else {
                        setland("en-US");
                      }
                      setshowsliderlanguafe(!showsliderlanguafe);
                    }}
                  >
                    <img
                      src={tran}
                      alt=""
                      className=" mx-auto lg:mt-[15%] mt-[0]"
                    />
                  </div>
                </li>
              </ul>
            </div>
            {/* onclick nav false */}
            <div
              className={
                direction === "ltr"
                  ? "fixed w-[50vw] h-[100vh]  top-[-10%] left-0 "
                  : "fixed w-[50vw] h-[100vh]  top-[-10%] right-0 "
              }
              onClick={() => setshownav(false)}
            ></div>
          </>
        )}
      </div>

      {/* second nav */}
  
    </div>
  );
}

export default Header;
