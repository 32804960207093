import React from 'react'
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next'
import { Fade } from 'react-reveal';

function DetailTitle({
  direction,
  image,
  explain,
  title,
  sub,
  metaTitle,
  metaDescription,
  imageAlt,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{metaTitle} </title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className=" lg:pt-[5%] md:pt-[10%] sm:pt-[10%] ">
        <h1 className="text-center lg:text-5xl uppercase luc md:text-3xl sm:text-2xl md:pt-[5%] lg:pt-[10%]  sm:pt-[10%]">
          {t(`${title}`)}
        </h1>

        <div className="flex lg:flex-row md:flex-row sm:flex-col w-[90%] m-auto lg:mt-[5%]  md:mt-[5%] sm:mt-[5%] justify-between items-center">
          <img
            src={image}
            alt={imageAlt}
            className="lg:w-[40%] md:w-[40%] sm:w-[70%] sm:m-auto lg:m-0 md:m-0 "
          />
          <Fade right cascade>
          <p
            className={
              direction === "ltr"
                ? "lg:w-[54%] md:w-[55%] sm:w-[95%] sm:m-auto lg:m-0 md:m-0   text-left lg:text-2xl lg:text-left md:text-left sm:text-center"
                : "lg:w-[54%] md:w-[55%] sm:w-[95%] sm:m-auto lg:m-0 md:m-0   text-right lg:text-2xl lg:text-right md:text-right sm:text-center"
            }
          >
            <span className="block mb-[5%]">{t(`${sub}`)}</span>
            {t(`${explain}`)}
          </p>
          </Fade>
        </div>
      </div>
    </>
  );
}

export default DetailTitle