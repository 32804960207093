import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import img1 from "../asset/images/stationary/30.png";
import img2 from "../asset/images/stationary/renewable_icon.png";
import arrowBlue from "../asset/images/stationary/arrow-blue.png"
import { Fade } from "react-reveal";
function DetailSpecific({ image, direction, madeon, size, four, imageAlt }) {
  const { t } = useTranslation();
  const [cat, setcat] = useState("297 X 420 ( L X W )");
  const [standardBackage, setstandardBackage] = useState("5");
  useEffect(() => {
    if (size === "on") {
      setcat("210 X 297 ( L X W )");
    } else {
      setcat("297 X 420 ( L X W )");
    }
    if (four === "yes") {
      setstandardBackage("4/5");
    } else {
      setstandardBackage("5");
    }
  }, []);

  const con1 = [
    { id: 1, title: "A4", cat: cat },
    { id: 2, title: "500", cat: "Sheets of paper per ream" },
    { id: 3, title: standardBackage, cat: "Reams per box" },
  ];
  const con2 = [
    { id: 1, title: "A3", cat: "297 X 420 ( L X W )" },
    { id: 2, title: "500", cat: "Sheets of paper per ream" },
    { id: 3, title: standardBackage, cat: "Reams per box" },
  ];
  return (
    <div className=" mt-[10%] pb-[7%]">
      <h1 className="text-center lg:text-4xl uppercase luc md:text-3xl sm:text-2xl">
        {t("Specifications")}
      </h1>

      <div className="flex items-start w-[90%] m-auto mt-[5%] lg:flex-row md:flex-row sm:flex-col justify-between">
        <div className="lg:w-[35%] md:w-[37%] sm:w-[60%] sm:m-auto lg:m-0 md:m-0  ml-[1%]">
          <Fade left >
            
          <img src={image} alt={imageAlt} className="" />
          </Fade>
          <div
            className={
              direction === "ltr"
                ? "border-b-4 border-[#6188D6] lg:w-[40%] md:w-[60%] sm:hidden md:block lg:block  pb-[2%] text-[#6188D6] "
                : "border-b-4 border-[#6188D6] lg:w-[30%] md:w-[40%] sm:hidden md:block lg:block  pb-[2%] text-[#6188D6] "
            }
          >
            <Link
              to="/stationary"
              className=" text-[#6188D6] inline lg:text-3xl md:text-2xl"
            >
              <img
                src={arrowBlue}
                alt="arrow"
                className={
                  direction === "ltr"
                    ? "inline mx-[5%] w-[15%] "
                    : "inline mx-[5%] w-[15%] rotate-180"
                }
              />
              {t("Go Back")}
            </Link>
          </div>
        </div>

        <div className="lg:w-[60%] md:w-[60%] sm:w-[90%] sm:m-auto lg:m-0 md:m-0 lg:pt-[5%]">
          {/* item1 */}

          <div>
            <h1
              className={
                direction === "ltr"
                  ? "lg:text-[1.75rem]  sm:text-base border-b-2 border-white pb-[5%] lg:text-left md:text-left sm:text-center"
                  : "lg:text-[1.75rem]  sm:text-base border-b-2 border-white pb-[5%] lg:text-right md:text-right sm:text-center"
              }
            >
              {t("A4 Reams & Boxes for Retail (Standard Packaging)")}
            </h1>
          </div>
          {/* item 3 */}
          <div className="flex justify-between border-b-2 border-white lg:pr-[10%]   md:pr-[4%] sm:pr-[2%] lg:text-xl  md:text-base  sm:text-sm items-baseline ">
            {con1.map((item) => (
              <div className="lg:w-auto md:w-auto sm:w-[31%]">
                <p className="mt-[12%]">{t(`${item.title}`)}</p>
                <p className="mt-[5%] pb-[5%]">{t(`${item.cat}`)}</p>
              </div>
            ))}
          </div>

          {/* item4 */}
          <div className="flex mt-[5%] lg:justify-start md:justify-start sm:justify-center">
            <div className="w-[40%] shadow-xl shadow-[#4b4b4b34] lg:text-xl md:text-lg sm:text-base lg:h-[130px] md:text-center lg:text-left sm:text-center">
              <p className=" mt-[5%] font-serif text-center">
                {t("Basic Weight (G/M2)")}
              </p>
              <p className="  text-center  mt-[5%]">{t("80")}</p>
            </div>
            <div className="w-[40%] mx-[4%] shadow-[#4b4b4b34] shadow-xl lg:h-[130px]">
              {madeon === "off" ? (
                <>
                  <p className=" ml-[8%] mt-[5%] font-serif lg:text-xl md:text-lg sm:text-base text-center lg:text-left md:text-center">
                    {t("Technology")}
                  </p>

                  <div
                    className={
                      direction === "ltr"
                        ? "flex ml-[8%] mt-[5%] lg:items-end md:items-center  justify-between lg:flex-row md:flex-col items-center sm:flex-col "
                        : "flex mr-[8%] mt-[5%] lg:items-end md:items-center  justify-between lg:flex-row md:flex-col items-center sm:flex-col "
                    }
                  >
                    <img src={img1} alt={imageAlt} className="w-[40%]" />
                    <p
                      className={
                        direction === "ltr"
                          ? "text-xs lg:ml-[3%] lg:mr-[1%] lg:text-left md:text-left sm:text-center "
                          : "text-xs lg:mr-[3%] lg:ml-[1%] lg:text-right md:text-right sm:text-center "
                      }
                    >
                      {t("Enhanced with ProDigi™ HD Print Technology")}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p
                    className={
                      direction === "ltr"
                        ? " ml-[8%] mt-[5%] font-serif lg:text-xl md:text-lg sm:text-base text-center lg:text-left    md:text-center"
                        : " mr-[8%] mt-[5%] font-serif lg:text-xl md:text-lg sm:text-base text-center lg:text-right md:text-center"
                    }
                  >
                    {t("Made From")}
                  </p>

                  <img
                    src={img2}
                    alt=""
                    className=" lg:w-[25%] md:w-[25%] sm:w-[50%]  m-auto"
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={
              direction === "ltr"
                ? "border-b-4 border-[#6188D6] w-[40%] my-[5%] m-auto  lg:hidden md:hidden  pb-[2%] text-[#6188D6] "
                : "border-b-4 border-[#6188D6] w-[30%] my-[5%] m-auto  lg:hidden md:hidden  pb-[2%] text-[#6188D6] "
            }
          >
            <Link
              to="/stationary"
              className=" text-[#6188D6] inline lg:text-3xl md:text-2xl"
            >
              <img
                src={arrowBlue}
                alt="arrow"
                className="inline mx-[5%] w-[15%] ref"
              />
              {t("Go Back")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailSpecific;
