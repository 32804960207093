import React from 'react'
import DetailFeatures from '../components/DetailFeatures'
import DetailSpecific from '../components/DetailSpecific'
import DetailTitle from '../components/DetailTitle'
import { useParams } from 'react-router-dom'
import imge1 from '../asset/images/stationary/2.png'
 import imge2 from '../asset/images/stationary/1.png'
 import imge4 from "../asset/images/stationary/digital printiung paper.png";
 import ixoraimage from '../asset/images/stationary/product_ixora.png'
 import digimage1 from '../asset/images/stationary/digital.png'
 import ixoraimage1 from '../asset/images/stationary/ixora .png'
import DetailFeatures2 from '../components/DetailFeatures2'



function WoodDetail({ direction }) {
  const { details } = useParams()

  const copier = {
    title: "PaperOne™ Copier",
    subtitle: "A4 copy paper engineered for high speed and high volume copying",
    explainTitle:
      "Made for laser printers and high-volume copiers, PaperOne™ Copier paper offers consistent smoothness and performance to ensure that each print is achieved with high quality and free from production problems, Graphics and text will both appear sharp and easy to read making PaperOne™ copier the best A4 copier paper wholesale stores can sell or acquire.",
    img: imge1,
    metaTitle: "Platinum Copier",
    metaDescription: "Multi-purpose white paper, Order it Now from Platinum ",
    imageAlt: "Paper one High-speed premium copy paper Qatar",
  };
  // ---------------digial-------
  const digital = {
    title: "PaperOne™ Digital",
    subtitle: "Designed for digital color - laser & inkjet printing",
    explainTitle:
      "If you are seeking the best digital printing paper then PaperOne™ Digital is a cut above other papers when it comes to impressing a reader with any high-resolution text or graphics, Offering the best in digital color paper and digital copier paper perfected to serve all modern copy and inkjet technology.",
    img: digimage1,
    metaTitle: "Platinum Digital Paper",
    metaDescription:
      "Present your work in high quality with premium digital laser & inkjet paper, Order it now from Platinum.",
    imageAlt: "Premium digital inkjet & laser paper Qatar",
  };
  // ---------------ixora-------
  const ixora = {
    title: "IXORA",
    subtitle: "Multi-purpose white paper",
    explainTitle:
      "IXORA is a premium A4 white paper that meets your daily printing needs, in the office and at home, It is engineered for high-speed printing & high volume copying while delivering excellent image contrast.",
    img: ixoraimage1,
    metaTitle: "Platinum IXORA",
    metaDescription:
      "If you're shopping for the home,office, consider Multi-purpose white copy paper. IXORA. order it now from Platinum. ",
    imageAlt: "Platinum IXORA",
  };
  



  console.log(details);
  return (
    <div className="bg-[#d1d1d1] ref">
      {details === "copier" && (
        <>
          {" "}
          <DetailTitle
            image={copier.img}
            explain={copier.explainTitle}
            title={copier.title}
            sub={copier.subtitle}
            direction={direction}
            metaTitle={copier.metaTitle}
            metaDescription={copier.metaDescription}
            imageAlt={copier.imageAlt}
          />
          <DetailFeatures />
          <DetailSpecific
            image={imge2}
            direction={direction}
            madeon="off"
            size="off"
            four="no"
            imageAlt={copier.imageAlt}
          />
        </>
      )}
      {details === "digital" && (
        <>
          {" "}
          <DetailTitle
            image={digital.img}
            sub={digital.subtitle}
            explain={digital.explainTitle}
            title={digital.title}
            direction={direction}
            metaTitle={digital.metaTitle}
            metaDescription={digital.metaDescription}
            imageAlt={digital.imageAlt}
          />
          <DetailFeatures />
          <DetailSpecific
            image={imge4}
            direction={direction}
            madeon="off"
            size="on"
            four="yes"
            imageAlt={digital.imageAlt}
          />
        </>
      )}
      {/* ixora */}
      {details === "ixora" && (
        <>
          {" "}
          <DetailTitle
            image={ixora.img}
            sub={ixora.subtitle}
            explain={ixora.explainTitle}
            title={ixora.title}
            direction={direction}
            metaTitle={ixora.metaTitle}
            metaDescription={ixora.metaDescription}
            imageAlt={ixora.imageAlt}
          />
          <DetailFeatures2 />
          <DetailSpecific
            image={ixoraimage}
            direction={direction}
            madeon="on"
            size="on"
            four="no"
            imageAlt={ixora.imageAlt}
          />
        </>
      )}
    </div>
  );
}

export default WoodDetail