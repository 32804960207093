import { isElementType } from '@testing-library/user-event/dist/utils';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { Link, NavLink } from 'react-router-dom';

function Subwood({ direction }) {
  const { t } = useTranslation();
  const woodTitle = [
    {
      id: "1",
      title: "PaperOne™ Digital",
      des: "Digital color paper Designed for digital color, laser & inkjet printing.",
      link: "/stationary/digital",
    },
    {
      id: "2",
      title: "PaperOne™ COPIER",
      des: "Digital printing paper is Designed for speed in high-volume copying.",
      link: "/stationary/copier",
    },
    {
      id: "3",
      title: "Ixora",
      des: "Multi-purpose white paperPaperOnetm All purpose.",
      link: "/stationary/ixora",
    },
  ];
  return (
    <>
      <div
        className={
          direction === "ltr"
            ? "lg:text-left md:text-left sm:text-center lg:pl-[8%] md:pl-[6%] "
            : "lg:text-right md:text-right sm:text-center lg:pr-[8%] md:pr-[6%] "
        }
      >
        <h1 className="lg:text-5xl md:text-3xl sm:text-2xl luc text-[white]   lg:pt-[10%] md:pt-[7%] ">
          {t("We offer Paperone’s:")}
        </h1>
        <Fade bottom  cascade>
          
        {woodTitle.map((item) => (
          <div className=" lg:text-3xl md:text-lg items-start sm:mt-[7%] md:mt-0 lg:mt-0">
            <div className="lg:w-[80%] md:w-[80%] sm:w-[90%] sm:m-auto lg:m-0 md:m-0">
              <div className="flex sm:justify-center  lg:justify-start md:justify-start items-center">
                <img
                  src="./images/wood/Ellipse 4.png"
                  alt=""
                  key={woodTitle.id}
                  className={
                    direction === "ltr"
                      ? "lg:mt-[6%] md:mt-[6.5%] mr-[2%] w-[2.5%]"
                      : "lg:mt-[6%] md:mt-[6.5%] ml-[2%] w-[2.5%]"
                  }
                />

                <p className="lg:mt-[5%]  md:mt-[4%]">{t(`${item.title}`)}:</p>
              </div>
              <p className="mx-[3.5%]">{t(`${item.des}`)}</p>
              <p className=" mx-[3.5%] lg:text-2xl md:text-lg sm:text:sm mt-[2%]  text-[#6188D6]">
                {" "}
                <Link
                  to={item.link}
                  className="lg:text-2xl md:text-lg sm:text:sm"
                >
                  {t("Go To Product")}
                </Link>
                <hr className="bg-[#6188D6] h-[3px] lg:w-[25%] md:w-[28%] sm:w-[38%] sm:m-auto lg:m-0 md:m-0" />
              </p>
            </div>
          </div>
        ))}
              </Fade>
        <p className="lg:text-3xl md:text-lg mt-[5%]">
          {t("Perfected for the modern home & office equipment")}
        </p>
      </div>
    </>
  );
}

export default Subwood