import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../asset/images/stationary/Group 87.png";
import img2 from "../asset/images/stationary/Group 88.png";
import img3 from "../asset/images/stationary/Group 89.png";

function DetailFeatures2() {
  const { t}= useTranslation()
  const images2 = [
    {
      id: 1,
      title: "Usage Area",
      image: img3,
      exp: "IXORA is specially developed for high volume copying and printing in copier machines using the xerographic principle. IXORA is very suitable to be used in fast speeds and trouble-free printers and copiers",
    },
    {
      id: 2,
      title: "Binding",
      image: img2,
      exp: "IXORA is ideal for stitch and thread binding. In order for the adhesive to stick to the paper, the sheet should be trimmed 0.5 - 1.0 mm after being perfect bound",
    },
    {
      id: 3,
      title: "Printing",
      image: img1,
      exp: "IXORA is designed to deliver consistent smooth, sharp printing on both simplex and duplex printing. Ideal for both monochrome and color copying and printing",
    },
  ];
  return (
    <div className="mt-[10%]">
      <h1 className=" text-center lg:text-4xl uppercase luc md:text-3xl sm:text-2xl">
        {t("Features & Benefits")}
      </h1>
    
      <div className=" w-[80%] flex m-auto lg:justify-between md:justify-between  sm:flex-col md:flex-row lg:flex-row flex-wrap mt-[5%] luc">
        {images2.map((item) => (
          <div key={item.id} className=" lg:w-[30%] md:w-[30%] sm:w-[90%] sm:m-auto lg:m-0 md:m-0   lg:mt-0 md:mt-0 lg:border-0 md:border-0  sm:border-2 sm:mt-[10%]">
            <img
              src={item.image}
              alt=""
              className="lg:w-[50%] md:w-[50%] sm:w-[50%] m-auto sm:mt-[6%] lg:mt-0 md:mt-0 "
            />
            <p className="text-center lg:text-xl  sm:text-base md:text-xs ">
              {t(`${item.title}`)}
            </p>
            <p className="text-center lg:text-xl  sm:text-base md:text-xs ">
              {t(`${item.exp}`)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DetailFeatures2;
