import React from "react";
import { useTranslation } from "react-i18next";
import Wood1 from "../components/Wood1";
import Wood2 from "../components/Wood2";
import imagedowm from "../asset/images/stationary/main.png";
import { Bounce, Fade } from "react-reveal";
import { Helmet } from "react-helmet";

function Paperone({ direction }) {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Platinum | Office & School stationary</title>
        <meta
          name="Shopoffice and school stationery
Stationery for students, teachers, offices, and companies."
        />
      </Helmet>
      <div>
        {" "}
        <div
          className="w-[100vw] lg:h-[82vh]  md:h-[350px] sm:h-[38vh]  to-[white] shad xs:pt-[7%] sm:pt-[7%] md:pt-[1%]  ref lg:pt-[1%] text-white relative "
          style={{
            backgroundImage: "url(./images/home/slider2.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1
            className="text-white text-center w-[80%] m-auto lg:text-6xl md:text-3xl sm:text-xl 
            leading-[120px]   mt-[12%] sm:pt-[5%] lg:pt-0 md:pt-0 luc"
          >
            {t(
              "Specialized in the sales and distribution of stationery items in Qatar."
            )}
          </h1>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url(./images/wood/bg.png )",
          backgroundSize: "cover",
        }}
        className="bg-[#D1D1D1] lg:pt-[8%] md:pt-[7%] sm:pt-[3%]"
      >
        <Wood1 direction={direction} />

        <p
          className={
            direction === "ltr"
              ? "md:w-[55%] lg:w-[55%] sm:w-[90%] lg:ml-[12%] md:ml-[8%] mt-[0%] lg:text-3xl md:text-lg  sm:text-center md:text-left lg:text-left sm:mt-[8%] sm:m-auto md:m-0 lg:m-0"
              : "md:w-[55%] lg:w-[55%] sm:w-[90%] lg:mr-[12%] md:mr-[8%] mt-[0%] lg:text-3xl md:text-lg  sm:text-center md:text-right lg:text-right sm:mt-[8%] sm:m-auto md:m-0 lg:m-0 "
          }
        >
          <h1 className="text-white lg:text-5xl md:text-3xl sm:text-2xl ">
            PaperOne
          </h1>
          <div className="w-[25%] bg-[white] mt-[10px] h-[3px] lg:m-0 md:m-0 sm:m-auto "></div>
          <p className="mt-[5%]">
            {t(
              "Leader in the paper-making industry PaperOne™ has made its name as a premium quality paper brand designed for the most demanding high-speed printing and copying tasks"
            )}
          </p>
        </p>
        <Wood2 direction={direction} />

        <div className="flex items-center justify-center mt-[20%] pb-[7%] lg:w-[60%]  md:w-[60%] sm:w-[75%] m-auto md:py-[12%] sm:py-0 lg:py-[7%] ">
          <Fade >
            <img
              src={imagedowm}
              alt="Three types of paper one papers"
              classsName=" lg:w-[12%] md:w-[11%] sm:w-[80%] "
            />
          </Fade>
        </div>
      </div>
    </>
  );
}

export default Paperone;
