import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import face from '../asset/images/layout/face.png'
import inst from '../asset/images/layout/inst.png'
import link from '../asset/images/layout/link.png'
import loca from '../asset/images/layout/loc.png'
import phon from '../asset/images/layout/phone.png'
import mess from '../asset/images/layout/mesage.png'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer({ direction }) {
  const [data, setdata] = useState("")
  
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('email22222', 'template_80mcz8a', form.current, 'uES2k1f4Vlc45n1tJ')
      .then(
        (result) => {
          toast.success("Message Sent it successfully ", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }).catch(
        (error) => {
          toast.error("Message Not Sent", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
    setdata("")
  }
  const {t}=useTranslation()
  return (
    <div className="  lg:h-[100%] h-[100%]   bg-[#3A3A3A] text-white">
      <ToastContainer />

      <div className="pt-[5%] ">
        <div className="lg:w-[90%] md:w-[96%] sm:w-[98%]   flex lg:flex-row md:flex-row sm:flex-col m-auto lg:justify-around md:justify-around sm:justify-center sm:items-center  lg:items-baseline  md:items-baseline">
          {/* ----------- section one------------ */}
          <div
            className={
              direction === "ltr"
                ? "lg:w-[25%] md:w-[40%] sm:w-[90%] sm:mt-[5%]  text-lg   lg:text-left md:text-left sm:text-center "
                : "lg:w-[25%] md:w-[40%] sm:w-[90%] sm:mt-[5%]  text-lg   lg:text-right md:text-right sm:text-center "
            }
          >
            <p>Contact information</p>
            <a href="tel:+974 40197722">
              <p className="lg:mt-[3.5%] md:mt-[3.5%]  ">
                <img
                  src={phon}
                  alt=""
                  className={
                    direction === "ltr"
                      ? "inline w-[7%] mr-[4%]"
                      : "inline w-[7%] ml-[4%]"
                  }
                />
                <span style={{ direction: "ltr" }}>+974 40197722</span>
              </p>
            </a>
            <a href="mailto:Info@p-ITC.com">
              <p className="lg:mt-[2.2%] md:mt-[2.2%] ">
                <img
                  src={mess}
                  alt=""
                  className={
                    direction === "ltr"
                      ? "inline w-[7%] mr-[4%]"
                      : "inline w-[7%] ml-[4%]"
                  }
                />
                Info@p-ITC.com
              </p>
            </a>
            <a href="https://goo.gl/maps/AkfFooD7BRR1XkBH6">
              <p className="lg:mt-[2.2%] md:mt-[2.2%] ">
                <img
                  src={loca}
                  alt=""
                  className={
                    direction === "ltr"
                      ? "inline w-[7%] mr-[4%]"
                      : "inline w-[7%] ml-[4%]"
                  }
                />
                Qatar-353
              </p>
            </a>
          </div>

          {/* section2 */}
          <div
            className={
              direction === "ltr"
                ? "lg:w-[25%] md:w-[25%] text-lg  sm:w-[90%] lg:text-left md:text-left sm:text-center sm:mt-[7%] md:mt-0 lg:mt-0"
                : "lg:w-[25%] md:w-[25%] text-lg  sm:w-[90%] lg:text-right md:text-right sm:text-center sm:mt-[7%] md:mt-0 lg:mt-0"
            }
          >
            <p className="lg:text-lg ">{t("Stationary")}</p>
            <Link
              to="/stationary/ixora"
              className="block lg:mt-[3.5%] md:mt-[4%]"
            >
              {" "}
              {t("Ixora (paper one)")}
            </Link>
            <Link
              to="/stationary/copier"
              className="block lg:mt-[2.2%] md:mt-[4%]"
            >
              {" "}
              {t("Copier (paper one)")}
            </Link>
            <Link
              to="/stationary/digital"
              className="block lg:mt-[2.2%] md:mt-[4%]"
            >
              {" "}
              {t("Digital (paper one)")}
            </Link>
          </div>
          {/* section3 */}
          <div
            className={
              direction === "ltr"
                ? "lg:w-[10%] md:w-[10%] sm:w-[90%] text-lg lg:text-left md:text-left sm:text-center sm:mt-[7%] md:mt-0 lg:mt-0"
                : "lg:w-[10%] md:w-[10%] sm:w-[90%] text-lg lg:text-right md:text-right sm:text-center sm:mt-[7%] md:mt-0 lg:mt-0"
            }
          >
            <p className="text-lg ">{t("Wood")}</p>
            <Link to="/wood" className="block lg:mt-[3.5%] md:mt-[4%]">
              {" "}
              {t("Beech")}
            </Link>
            <Link
              to="/stationary/copier"
              className="block lg:mt-[2.2%] md:mt-[4%]"
            >
              {" "}
              {t("FIR")}
            </Link>
          </div>
          {/* ------------------section 4 -----------------------*/}
          <div className="lg:w-[30%] md:w-[30%] sm:w-[80%] lg:m-0 md:m-0 sm:m-auto  ">
            <div
              className="h-[4px] w-[100%] bg-red-500"
              style={{ visibility: "hidden" }}
            >
              x{" "}
            </div>
            <div
              className={
                direction === "ltr"
                  ? "flex     lg:text-lg  lg:ml-[10%] md:ml-[5%]  sm:ml-[3%] "
                  : "flex     lg:text-lg  lg:mr-[10%] md:mr-[5%]  sm:mr-[3%] "
              }
            >
              <p
                className={
                  direction === "ltr"
                    ? "lg:block md:block  mr-[10%]  mt-[10%] "
                    : "lg:block md:block  ml-[10%]  mt-[10%] "
                }
              >
                {t("Follow")}
              </p>
              <div className="flex items-center    mt-[10%]  justify-around  ">
                <a
                  href="https://instagram.com/platinum.qa?igshid=NDRkN2NkYzU="
                  className="lg:w-[27px] md:w-[23px] sm:w-[19px] lg:mx-[10px] md:mx-[10px] sm:mx-0   "
                >
                  <img src={inst} alt="instagram" className="" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100081344557861"
                  className="lg:w-[15px] mx-[10px] md:w-[10px] sm:w-[10px]  "
                >
                  <img src={face} alt="facebook" className="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/platinum-internation/"
                  className="lg:w-[27px] md:w-[23px] sm:w-[19px]   "
                >
                  <img src={link} alt="linked in" className="" />
                </a>
              </div>
            </div>
            <form ref={form} action="" onSubmit={sendEmail} className="pt-[5%]">
              <input
                value={data}
                onChange={(e) => setdata(e.target.value)}
                type="text"
                name="message"
                placeholder={t("Your Message")}
                className="w-[90%] lg:text-lg md:text-lg sm-text-lg lg:py-2 md:py-2 sm:py-1  rounded-full lg:px-5 md:px-3 sm:px-2 bg-[#C4C4C4] mt-[5%] md:mt-0  sm:m-auto lg:m-0 md:m-0  "
              />
              <input type="submit" hidden />
            </form>
          </div>
        </div>

        {/*------------------------ hr----------------------- */}

        <hr className="w-[40%] m-auto lg:mt-[2%] md:mt-[4%] sm:mt-[6%] " />
        <p className="text-center py-[1%] seg text-[#ADADAD] sm:text-sm md:text-base lg:text-base">
          © 2022 Platinum | All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
