import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-reveal'
import point from "../asset/images/wood/Ellipse 4.png"
import middle from "../asset/images/wood/middle.jpg"
function Products({ direction}) {
  const {t}=useTranslation()
  return (
   
      <div className="w-[90%] m-auto flex justify-between lg:flex-row md:flex-row sm:flex-col-reverse sm:mt-[5%] lg:mt-0 md:mt-0  lg:text-5xl md:text-3xl sm:text-2xl">
        <div className="lg:pt-[5%] md:pt-[5%] sm:pt-[10%] sm:m-auto md:m-0 lg:m-0 text-center lg:w-[40%] md:w-[30%]    ">
          <h1
            className={
              direction === "ltr"
                ? "text-white lg:text-5xl md:text-3xl sm:text-2xl  lg:text-left md:text-left sm:text-center "
                : "text-white lg:text-5xl md:text-3xl sm:text-2xl  lg:text-right md:text-right sm:text-center "
            }
          >
            {t("Products")}
          </h1>
          <div className="flex mt-[10%] items-center sm:justify-center lg:justify-start md:justify-start md:pl-[4%] lg:pl-[4%]">
            <img
              src={point}
              alt="point"
              className={
                direction === "ltr" ? "w-[5%] mr-[7%]" : "w-[5%] ml-[7%]"
              }
            />
            <p>{t("Fir wood")}</p>
          </div>
          <div className="flex mt-[15%] items-center sm:justify-center lg:justify-start md:justify-start md:pl-[4%] lg:pl-[4%]">
            <img
              src={point}
              alt="point"
              className={
                direction === "ltr" ? "w-[5%] mr-[7%]" : "w-[5%] ml-[7%]"
              }
            />
            <p>{t("Beechwood")}</p>
          </div>
        </div>
        <img
          src={middle}
          alt="Wood planks Qatar"
          className="lg:h-[407px] md:h-[290px] sm:h-[200px] lg:w-[30%] md:w-[35%] sm:w-[70%] sm:m-auto lg:m-0 md:m-0"
        />
      </div>
  
  );
}

export default Products