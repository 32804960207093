import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-reveal';

function Wood1({ direction}) {
  const {t}= useTranslation()
  return (
    <div>
      <Fade bottom cascade>
        <div
          className={
            direction === "ltr"
              ? "w-[90%] lg:ml-[10%] md:ml-[5%] flex lg:flex-row md:flex-row sm:flex-col  justify-between sm:m-auto md:m-0 lg:m-0"
              : "w-[90%] lg:mr-[10%] md:mr-[5%] flex lg:flex-row md:flex-row sm:flex-col  justify-between sm:m-auto md:m-0 lg:m-0"
          }
        >
          <img
            src="./images/wood/Image-1.png"
            alt="Paper factory Doha"
            className="lg:h-[507px] md:h-[350px]"
          />
          <div
            className={
              direction === "ltr"
                ? "sm:m-auto lg:m-0 md:m-0 sm:text-center md:text-left lg:text-left"
                : "sm:m-auto lg:m-0 md:m-0 sm:text-center md:text-right lg:text-right"
            }
          >
            <h1 className="text-[#fff] luc  lg:text-5xl md:text-3xl sm:text-2xl lg:mt-[10%] md:mt-[5%]  ">
              {t("April")}
              <div className="w-[13%] bg-[white] mt-[10px] h-[3px] lg:m-0 md:m-0 sm:m-auto"></div>
            </h1>
            <p className="block  mt-[5%] lg:text-3xl md:text-lg lg:w-[95%] md:w-[95%] sm:w-[100%] ">
              {t(
                "Asia Pacific Resources International Limited (APRIL) Group is one of the largest, most technologically advanced,and efficient makers of pulp and paper productsin the world,Making products in liquid packaging, printing and writing paper, tissues, shopping bags, food packaging, magazines, and books, that are used by millions daily."
              )}
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Wood1