import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';


function ContactHead() {
  const {t} = useTranslation()

  
  return (
    <div
      className="bg-gradient-to-b from-[#585858] to-[#c1c6de31] text-white w-[100vw]
    lg:h-[570px] md:h-[420px] sm:h-[230px] font-serif    text-center
    "
    >
      <h1 className="text-center lg:pt-[23%] md:pt-[30%] sm:pt-[23%] lg:text-5xl md:text-4xl sm:text-3xl uppercase luc  ">
        {t("Contact Us")}
      </h1>

      <p className="luc mt-[1%] lg:text-lg md:text-base sm:text-sm w-[90%] m-auto">
        {t(
          "We value our clients and aim to offer them the best trading experience possible."
        )}
      </p>
    </div>
  );
}

export default ContactHead