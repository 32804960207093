import React, { lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';



const Video = lazy(() => import("../components/Video"));
const Slider = lazy(() => import("./Slider"));
const WoodHome = lazy(() => import("../components/WoodHome"));
const Trading = lazy(() => import("../components/Trading"));
const OurClient = lazy(() => import("../components/OurClient"));
function Home({ direction}) {
  const [changingbylang, setchangingbylang] = useState("")

  const [t, i18n] = useTranslation()
  useEffect(() => {
    if (direction === "rtl") {
      setchangingbylang("mr-[7%]");
    } else {
      setchangingbylang("ml-[7%]");
    }
  }, [direction]);


  return (
    <div>
      <Helmet>
        <title>Platinum | Paper and wood suppliers in Qatar</title>
        <meta
          name="description"
          content="find below the list of paper and wood products we provide A4, digital printing paper, Ixora multi-purpose white paper, and Fir and beech planks."
        />
      </Helmet>
      {/* slider1 */}
      <Slider />
      {/* ------------done slider------------- */}

      <OurClient direction={direction} />
      <div
        className=" bg-[#D1D1D1] "
        style={{
          backgroundImage: "url(./images/home/bg2.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Trading direction={direction} />

        {/* -------------------SITONARY--------------- */}
        <Video direction={direction} />
        {/* ------------------WOOD----------- */}

        <WoodHome direction={direction} />
      </div>
    </div>
  );
}

export default Home;
